
  .key-point-list-item {
    font-family: nova-light;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
  
  }
  
  .key-point-list-item svg {
    margin-right: 10px;
    margin-top: 5px;
    color: #1acb1a;
    font-size: 11px;
  }