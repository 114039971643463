.choose-outer-container {
  padding: 60px 0 60px 0;
  align-items: center;
  display: flex;
  overflow-x: hidden;
}
.choose-inner-container {
}
.choose-heading-section {
  text-align: center;
}
.choose-heading-section .top-title {
  font-size: 16px;
  color: grey;
  text-align: center;
}
.choose-heading-section .heading {
  color: #2b9123;
  font-size: 28px;
  margin-bottom: 10px;
}
.choose-heading-section .para {
  margin-bottom: 40px;
}



