.protected-nav-container {
  background: white;
  z-index: 2;
  position: relative;
  transition: all 600ms ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 15%;
  border-right: 1px solid #ccc;
  transition: all 500ms ease-in-out;
}
.protected-nav-container
  .ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  flex-direction: column !important;
  padding-left: 48px;
  justify-content: flex-start;
  align-items: flex-start;
}
.removeSlider {
  left: -20%;
  transition: all 500ms ease-in-out;
}
.protected-nav-container .ant-menu-item.ant-menu-item-only-child {
  font-size: 12px !important;
  color: #3e3838 !important;
  font-family: roboto !important;
}

.protected-nav-container .ant-menu-item.ant-menu-item-only-child {
  margin-right: 25px !important;
}
@media (min-width: 992px) {
  .protected-nav-container
    .ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    margin-left: 0 !important;
  }
}
.protectedNavActive span {
  color: #35a4d6 !important;
  transition: all 0.2s ease;
  transition-delay: 0.25s;
}

.slider-logo {
    position: relative;
    overflow: hidden;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.slider-logo img {
    position: absolute;
    top: -79px;
    width: 232px
}
