.choose-us-container {
  margin-bottom: 20px;
  /* height: 80vh; */
}
.choose-us-inner-container {
  height: 100%;
}
.choose-us-text-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.choose-us-text-container-top-title {
  font-size: 16px;
  color: #2b9123;
}
.choose-us-text-container-heading {
  font-size: 22px;
  color: #2b9123;
  margin-bottom: 10px;
}
.choose-us-text-container-detail {
  margin-bottom: 40px;
}
.choose-us-image-container {
  height: 100%;
}
.choose-us-image-container img {
  width: 100%;
  height: 350px;
  max-height: 100%;
  object-fit: contain;
  /* animation: moveChangeImage 1s linear 0s infinite alternate; */
}

/* @keyframes moveChangeImage {
  from {transform: scale(1);}
  to {transform: scale(1.1);}
} */

@media (max-width: 768px) {
  .choose-us-text-col {
    height: auto !important;
  }
  .choose-us-image-col {
    height: auto !important;
  }
}

.choose-text-row {
}
.choose-us-text-col {
}
