.factors-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.factors-image {
    display: inline-block;
    position: relative;
    border: 1px solid #ccc;
    padding: 5px;
    background: #f2f2f2;
    margin-bottom: 15px;
}

.factors-image img {
    width: 150px;
    height: 100px;
    object-fit: cover;
}

.s8:before, .s8:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 25px;
    left: 10px;
    width: 50%;
    height: 10%;
    top: 60%;
        max-width:300px;
    background: #777;
    -webkit-box-shadow: 0 35px 20px #777;
    -moz-box-shadow: 0 35px 20px #777;
    box-shadow: 0 35px 20px #777;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    transform: rotate(-8deg);
 }
 .s8:after {
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -o-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
    right: 10px;
    left: auto;
 }

.factors-title {
    text-transform: capitalize;
    font-size: 16px;
    font-family: nova-bold;
    margin-top: 5px;
    margin-bottom: 5px;
}

.factors-text {
    font-family: nova-light;
    text-align: center;
}