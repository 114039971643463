.fund-raiser-box {
  cursor: pointer;
  text-decoration: none !important;
  border: 1px solid #1496d1;
  padding: 20px 20px;
}
.fund-raiser-box-link {
  text-decoration: none;
  color: black;
}
.fund-raiser-box-link:hover {
  color: black;
}

.fund-raiser-box .fund-raiser-image {
  width: 100%;
  display: flex;
  justify-content: center;
}
.fund-raiser-image img {
  width: 50%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
  margin: auto;
}
.fund-raiser-box .fund-raiser-description {
  font-size: 14px;
  text-decoration: none !important;
  font-family: "nova-light";
  margin-top: 20px;
}
.fund-raiser-box .fund-raiser-type {
  font-size: 14px;
  color: #1496d1;
  font-family: "nova-bold";
}

.fund-raiser-info {
  text-decoration: none !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fund-raiser-box .funding-raised-left {
  text-decoration: none !important;
}
.funding-raised-right {
  text-decoration: none !important;
}
.funding-collected {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 2px;
  color: grey;
  font-family: "nova-light";
  text-decoration: none !important;
}
.fund-raiser-box .funding-bar {
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
}

.funding-inner-bar {
  background-color: #1496d1;
  width: 80%;
  height: 100%;
}

.fund-raiser-title-container {
  display: flex;
  position: relative;
  align-items: flex-start;
}

.fund-raiser-title-container .days-left {
  font-size: 12px;
  letter-spacing: 0.3px;
  position: absolute;
  text-decoration: none !important;
  right: 0;
}

.fund-raiser-title {
  font-size: 18px;
  margin-bottom: 10px;
  text-decoration: none !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "nova-bold";
  width: 70%;
  height: 47px;
}
.funding-section {
  font-size: 14px;
  width: 70%;
}
.fund-box-bottom {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.fund-box-button {
  margin-left: 10px;
}
.fund-box-button a {
  font-size: 12px;
  font-family: "nova-light";
  text-decoration: none;
  background-color: #1496d1;
  color: white;
  padding: 6px 10px;
}
