.latest-raisers-box {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
}
.latest-raisers-image {
  width: 200px;
  height: 47px;
  margin-right: 10px;
}
.latest-raisers-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.latest-raisers-text {
}
.latest-raiser-heading {
  font-family: "nova-bold";
  font-size: 16px;
  line-height: 0.9;
  text-transform: capitalize;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-decoration: none;
}
.fund-raiser-detail {
  display: -webkit-box;
  line-height: 0.9;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "nova-light";
  font-size: 12px;
}

@media (max-width: 992px) {
  .latest-raisers-image {
    height: 100px !important;
  }
}
