.project-page-container {
  min-height: 100vh;
  /* background: #2b9123; */
}
.project-page-inner-container {
  display: flex;
  position: relative;
}

.project-page-image-container {
  margin-bottom: 30px;
}
.project-page-image-container img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 5/3;
}
.project-page-text {
  font-family: "nova-light";
  margin-bottom: 20px;
}
.project-category {
  font-size: 14px;
  color: #1496d1;
  /* color: white; */
}
.project-title {
  /* color: white; */
  font-size: 22px;
  font-family: "nova-bold";
  width: 80%;
}
.progress-container {
  margin-bottom: 10px;
  margin-top: 30px;
}
.support-container {
}

.progress-container .funding-raised-left {
  text-decoration: none !important;
  /* color: white; */
}
.progress-container .funding-raised-right {
  text-decoration: none !important;
  /* color: white; */
}
.progress-container .funding-collected {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  text-decoration: none !important;
  width: 80%;
}
.progress-container .funding-bar {
  height: 6px;
  /* background: white; */
  background: rgba(0, 0, 0, 0.2);
  width: 80%;
}
.progress-container .days-left {
  font-size: 14px;
  padding: 5px 10px;
  /* background-color: white; */
  text-decoration: none !important;
}
.progress-container .days-left {
  background: transparent;
  /* color: white; */
  padding-left: 0;
}

.people-supported {
  /* color: white; */
  font-family: "nova-light";
}
.we-accept-container {
  margin-top: 20px;
}
.we-accept {
  display: flex;
}
.we-accept-heading {
  font-size: 18px;
  font-family: nova-light;
}
.we-accept div {
  height: 35px;
  overflow: hidden;
  position: relative;
}
.we-accept div:not(:last-child) {
  margin-right: 2px;
}
.esewa {
}
.esewa img {
  width: 25px;
}
.khalti {
}
.khalti img {
  width: 35px;
}
.ime-pay {
  width: 73px;
}
.ime-pay img {
  width: 90px;
  position: absolute;
  top: -18px;
  left: -13px;
}

@media (max-width: 992px) {
  /* .small-mobile-image {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  .large-device-project-heading {
    display: none;
  } */
  .people-supported {
    margin-top: 30px;
  }
  .progress-container {
    margin-top: 0px;
  }
  .fund-raiser-left {
    height: auto !important;
    padding: 0 !important;
    margin-bottom: 30px;
  }
  .fund-raiser-middle {
    height: auto !important;
    padding: 0 !important;
    margin-bottom: 30px;
  }
  .fund-raiser-right {
    height: auto !important;
    padding: 0 !important;
  }
  .project-page-container {
    padding-top: 30px;
  }

}
@media (min-width: 992px) {
  /* .large-device-project-heading {
    display: flex;
    flex-direction: column;
  }
  .small-mobile-image {
    display: none;
  } */
}

.fund-raiser-left {
  padding: 60px 20px 0 0;
  /* width: 30%; */
  height: calc(100vh - 80px);
  overflow-y: hidden;
}
.fund-raiser-middle {
  /* width: 40%; */
  height: calc(100vh - 80px);
  overflow-y: scroll;
  padding: 60px 20px;
}

.fund-raiser-middle::-webkit-scrollbar {
  width: 3px;
}

.fund-raiser-middle::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
  width: 5px;
}

.fund-raiser-middle::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
  width: 5px;

}

.fund-raiser-middle::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
  width: 5px;
}

.fund-raiser-middle::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
  width: 5px;

}

/* .fund-raiser-middle {
  scrollbar-width: medium;
}
.fund-raiser-middle {
  scrollbar-color: rgb(68, 68, 68) rgb(68, 68, 68);
} */

.fund-raiser-right {
  padding: 60px 20px;
  margin-bottom: 100px;
  /* width: 30%; */
  height: calc(100vh - 80px);
  overflow-y: hidden;
  z-index: 3;
}

.latest-raiser-title {
  font-size: 22px;
  font-family: "nova-bold";
  margin-bottom: 15px;
}
