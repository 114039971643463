.startup-wrap {}

.startup-heading-row {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.startup-container {}

.startup-heading {
    font-family: nova-bold;
    font-size: 18px;
    margin-bottom: 5px;
}

.startup-sub-heading {
    font-family: nova-light;
}

.startup-image-container img {
    width: 100%;
    object-fit: contain;
}

.startup-input {
    margin-bottom: 20px;
    position: relative;
}


.startup-input input {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
}

.startup-input textarea {
    width: 100%;
    border: 1px solid black;

}
.startup-input textarea:focus{
    border: 1px solid black;
}
.startup-input textarea:focus-visible{
    border: 1px solid black;
    outline: none;
}
.textarea-input {
    margin-top: 50px;
}


.startup-input input:active {
    border: none;
    border-bottom: 1px solid black;
}

.startup-input input:focus-visible {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
}

.startup-label {
    position: absolute;
    color: black;
    z-index: 2;
    top: 0;
    left: 2px;
}

.textarea-startup-label {
    position: absolute;
    top: -25px;
}

.startup-input input:focus~.startup-label {
    display: none;

}

.startup-label-off {
    display: none;
}

.startup-button {
    display: flex;
    justify-content: flex-end;
}

.startup-wrap .ant-modal-body {
    border-radius: 10px;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    border-radius: 10px !important;
    padding: 0px 24px!important;
}

.startup-wrap .ant-modal-content {
    overflow: auto;
    border-radius: 14px;
    width: 70%;
    margin: auto;
    padding: 20px 0;
}

@media (min-width: 448px) and (max-width: 532px) {
    .startup-wrap .ant-modal-content {
        width: 80%;
    }
}

@media (max-width: 448px) {
    .startup-wrap .ant-modal-content {
        width: 100%;
    }

    .startup-wrap .ant-modal-body {
        padding: 11px;
    }
}

.ant-modal-mask {
    background: rgba(9, 145, 190, 0.4);
}