.fund-raiser-page-container {
  min-height: 100vh;
  /* background-color: #2b9123; */
  padding: 60px 0 60px;
}
.fund-raiser-page-inner-container {
}

.fund-raiser-heading {
  text-align: left;
  margin-bottom: 0px;
}
.fund-raiser-header {
  font-size: 28px;
}
.fund-raiser-para {
}

.fund-raiser-body {
}
.fund-raiser-margin {
  margin-bottom: 40px;
}
.featured-project {
  /* min-height: 100vh; */
  /* background: white; */
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  flex-direction: column;
  justify-content: center;
}
.featured-media{
  width: 100%;
  overflow: hidden;
}
.featured-media:hover img{
  transform: scale(1.1);
}
.featured-media img {
  width: 100%;
  aspect-ratio: 5/4;
  object-fit: cover;
  transition: all 250ms ease-in-out;
}
.featured-project .fund-raiser-description {
  background-color: transparent;
  /* color: white; */
  padding: 0;
  margin-bottom: 15px;
}
.featured-project .funding-section {
  background-color: transparent;
  /* color: white; */
  margin-bottom: 10px;
  padding: 0;
}
.featured-project .days-left {
  background-color: transparent;
  /* color: white; */
  padding: 0;
}
.featured-project .funding-bar {
  background: rgba(0,0,0,0.2);
  /* background-color: white; */
}
.featured-project .fund-raiser-type {
  /* color: white; */
}

.latest-projects-header {
  font-size: 28px;
  /* color: white; */
  margin-bottom: 30px;
  font-family: 'nova-bold';
}

.featured-project .funding-inner-bar{
  background-color: #1496d1;
  width: 80%;
  height: 100%;

}
.fund-raiser-card-container{
  margin-bottom: 70px;
  /* height: 100vh; */
  justify-content: center;
display: flex;
align-items: center
}
.fund-top-title{
  color: grey;
  font-size: 16px;
  font-family: 'nova-light';
}
.fund-card-title{

  font-family: 'nova-bold';
  font-size: 22px;
}
