/* hero section */
.hero-section {
  height: 100vh;
  width: 100%;
  position: relative;
}

.hero-section .hero-container {
  height: 100vh;
}

.hero-section .hero-container .hero-img {
  width: 100%;
}

.hero-section .hero-container .hero-img img {
  width: 750px;
  margin-left: -160px;
}

.hero-section .hero-content .hero-title {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: nova-bold;
  color: black;
  letter-spacing: -2px;
  line-height: 0.95;
}

.hero-section .hero-content .hero-desc {
  color: rgba(0, 0, 0, 0.7);
}

.hero-section .hero-content button {
  margin-top: 10px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

.hero-section .hero-content .learn-more {
  width: 12rem;
  height: auto;
}

.hero-section .hero-content .learn-more .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #1496d1;
  border-radius: 0;
}

.hero-section .hero-content .learn-more .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: white;
}

.hero-section .hero-content .learn-more .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.hero-section .hero-content .learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hero-section .hero-content .learn-more .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #1496d1;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.hero-section .hero-content button:hover .circle {
  width: 100%;
}

.hero-section .hero-content button:hover .circle .icon.arrow {
  background: white;
  -webkit-transform: translate(1rem, 0);
  transform: translate(1rem, 0);
}

.hero-section .hero-content button:hover .button-text {
  color: white;
}

@media only screen and (max-width: 768px) {
  .hero-section .hero-content {
    height: 100%;
  }
}

.hero-section .globe-earth {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 50px;
}

.hero-section .globe-earth canvas {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .hero-section .globe-earth {
    left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .hero-section .globe-container {
    position: absolute;
    left: 50%;
    /* top: 64%; */
    z-index: -1;
  }
  .hero-section .hero-content {
    background-color: #ffffffb8;
    padding: 20px;
    -webkit-box-shadow: 3.9px 0px 6px 0px rgba(0, 0, 0, 0.24);
    box-shadow: 3.9px 0px 6px 0px rgba(0, 0, 0, 0.24);
  }
}
@media (max-width: 992px) {
  .globb {
    /* right: -20px; */
    height: 100vh !important;
    right: -200px!important;
  }
}
@media (max-width: 768px) {
  .globb {
    right: 0 !important;
  }
}
.globb {
  position: absolute;
  right: -260px;
  top: -40px;
  width: 100%;
  height: 100%;
}
