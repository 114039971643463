@media (min-width: 400px) and (max-width: 576px) {
  .stats-row {
  }
}
@media (min-width: 768px) {
  .stats-row {
  }
}
.stats-side-margin {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 25%;
}
.stats-row {
  display: flex;
  justify-content: space-around;
  background: #f2f2f2;
  border-radius: 20px;
  align-items: center;
}

.stats-cards-container{
  margin-bottom: 30px;
  margin-top: 10px;
}