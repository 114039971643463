.service-inner-page-loan {
    padding: 0px 0 60px;
}

.service-inner-loan-heading {
    font-family: nova-bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.service-inner-loan-text {
    font-family: nova-light;
}

.loan-first-image {
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.loan-first-image img {
    max-height: calc(100vh - 100px);
    max-width: 120%;
    object-fit: cover;
}

.factors-affecting-loan {
    margin-top: 50px;
}

.factors-boxes {}

.loan-inquiry-button {
    margin-top: 30px;
}