.data-container {
  margin-bottom: 30px;
}
.data-heading {
  font-size: 12px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.data-box {
  display: flex;
}

.data-box input {
  border: none;
  outline: none;
  width: 80%;
}

.data-box input:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}
.data-box textarea {
  outline: none;
  width: 70%;
  border: none;
  margin-right: 53px;
  background: transparent;
  border-bottom: 1px solid #1496d1;
  font-family: nova-light;
}

.data-box textarea:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}
.admin-button {
  border: 1px solid #1496d1;
  color: black;
  width: 70px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 20px;
  font-family: nova-light;
  font-size: 14px;
}

.update-button {
  border: 1px solid #1496d1;
  color: black;
  width: 70px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 20px;
  font-family: nova-light;
  font-size: 14px;
}

.choose-button {
  border: 1px solid #1496d1;
  color: black;
  background-color: transparent;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0 15px;
  border-radius: 20px;
  font-family: nova-light;
  font-size: 14px;
}
.table-button {
  border: 1px solid #8ab4c7;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 26px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: transparent;
}
.admin-sub-heading {
  margin-bottom: 20px;
  color: #1496d1;
  text-transform: capitalize;
}
.admin-header {
  font-family: nova-bold;
  font-size: 28px;
  margin-bottom: 30px;
}
.admin-inner-section {
  margin-bottom: 50px;
}

.admin-container .ant-table-cell {
  font-family: nova-light;
}

.admin-box-container {
  padding: 16px;
  padding-right: 16px;
  padding-left: 16px;
  background: #f2f2f2;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 30px;
}


