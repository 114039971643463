.service-item-new-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  max-width: 100% !important;
  background-color: #ccc;
  /* border-radius: 10px; */
  overflow: hidden;
}
@media (max-width: 992px) {
  .service-item-container {
    max-width: 100% !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .service-item-container {
    max-width: 100% !important;
  }
}
@media (max-width: 769px) {
  .service-item-container {
    max-width: 100% !important;
  }
}
.new-service-item-image {
  width: 100%;
  height: 70%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.new-service-item-image:hover img {
  transform: scale(1.1);
}
.new-service-item-image a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.new-service-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 250ms ease-in-out;
}


.service-item-detail {
  width: 100%;
  padding: 10px 10px;
}
.service-detail-heading {
  font-weight: bold;
}
.service-detail-body {
}

.new-service-name {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: black;
  z-index: 1;
  width: 60%;
  text-transform: capitalize;
  font-family: 'nova-bold';
}
.service-item-new-container .new-service-name-box {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  width: 100%;
  align-items: center;
}

.service-item-container {
  position: relative;
  z-index: 1;
}
/* .service-item-container::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
} */
.service-item-button .primary-button {
  border-radius: 0% !important;
  border-color: white !important;
  color: white !important;
  z-index: 1;
  width: 100px !important;
}
.new-item-button{
  font-size: 14px;
  font-family: 'nova-light';
  padding: 8px 15px;
  background-color: #1496d1;
  color: white!important;
  text-decoration: none;
}