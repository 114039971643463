.products-item-container {
  margin: auto;

  width: 92% !important;
  height: 235px !important;
  cursor: pointer;
  transition: all 250ms linear;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}
.products-item-container:hover {
}
.inner-container {
}
/* .products-container:hover {
  box-shadow: 1px 1px 1px 10xp #ccc;
} */
.products-item-container img {
  object-fit: cover;
  width: 100px;
  height: 100px;
  max-width: 100%;
  margin-bottom: 10px;
}
.products-align {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.product-body {
  text-align: left;
  height: 90%;
}
.product-description {
  text-align: left;
  margin-top: 50px;
}
.product-price {
  font-size: 16px;
  font-weight: bold;
}
.prduct-name {
}
.product-location {
  font-size: 14px;
}
.products-item-container .product-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  /* background: rgba(16, 138, 3, 0.7); */
  height: 100%;
  /* visibility: hidden; */
  transform: translateY(-100%);
  transition: all 250ms linear;
}

.product-name {
  position: absolute;
  /* color: white; */
  font-size: 28px;
  bottom: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  transform: translateY(107%);
  transition: all 250ms linear;
  font-weight: bold;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.prod-links {
  display: flex;
  justify-content: flex-start;
}
.prod-links a {
  height: 28px !important;
  width: 28px !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 250ms ease-in-out;
}
.prod-links a:hover {
  transform: scale(1.1);
}
.prod-links a:not(:last-child) {
  margin-right: 8px;
}
.prod-name {
  font-size: 18px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* margin-bottom: 10px; */
}
.prod-detail {
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 992px) {
  /* .products-item-container {
    height: 325px !important;
  }
  .products-align {
    align-items: flex-start !important;
  }
  .products-item-container img {
    height: 120px !important;
    margin-left: 0 !important;
    border-bottom-left-radius: 0!important;
  }
  .product-body {
    height: 100%!important;
    text-align: center!important;
  }
  .prod-links {
    display: flex;
    justify-content: center!important;
  } */
}

.product-text {
}
.product-heading {
  font-size: 18px;
  font-family: nova-bold;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 5px;
}
.product-para {
  display: -webkit-box;
  font-family: nova-light;
  font-size: 15px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: grey;
}

@media (max-width: 448px) {
  .product-small {
    width: 68%;
    margin: auto;
  }
}
