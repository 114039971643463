/* team section */
.team-section{
  overflow: hidden;
}
.team-section .team-list .row > div {
    padding: 0;
    margin: 0;
  }
  
  .team-card {
    position: relative;
  }
  
  .team-card .team-card-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: black;
    padding: 20px;
  }
  
  .team-card .team-card-overlay .overlay-content {
    -webkit-transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
    width: 0;
    height: 100%;
    background-color: white;
    position: relative;
    opacity: 0;
  }
  
  .team-card .team-card-overlay .team-info {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 30px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    overflow: hidden;
  }
  
  .team-card .team-card-overlay .team-info h3 {
    -webkit-transition: opacity 0.2s 0.1s, -webkit-transform 0.6s cubic-bezier(0.63, 0.07, 0.04, 0.95) 0.1s;
    transition: opacity 0.2s 0.1s, -webkit-transform 0.6s cubic-bezier(0.63, 0.07, 0.04, 0.95) 0.1s;
    transition: transform 0.6s cubic-bezier(0.63, 0.07, 0.04, 0.95) 0.1s, opacity 0.2s 0.1s;
    transition: transform 0.6s cubic-bezier(0.63, 0.07, 0.04, 0.95) 0.1s, opacity 0.2s 0.1s, -webkit-transform 0.6s cubic-bezier(0.63, 0.07, 0.04, 0.95) 0.1s;
    text-transform: uppercase;
    font-size: 2rem;
    font-family: nova-bold;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    opacity: 0;
    width: 100%;
  }
  
  .team-card .team-card-overlay .team-info h5 {
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
    transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
    transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
    font-size: 0.9rem;
    font-family: nova-thin;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    opacity: 0;
    width: 100%;
  }
  
  .team-card:hover .team-card-overlay .overlay-content {
    opacity: 1;
    width: 100%;
  }
  
  .team-card:hover .team-card-overlay .overlay-content .team-info h3 {
    font-size: 2rem;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  
  .team-card:hover .team-card-overlay .overlay-content .team-info h5 {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  
  /* testimonials */
  .tm-list .tm-desc {
    font-size: 1.5rem;
    font-family: nova-bold;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
  }
  
  @media only screen and (max-width: 768px) {
    .tm-list .tm-desc {
      font-size: 1em;
    }
  }
  
  .tm-list .tm-name {
    margin-top: 15px;
    font-size: 1.2rem;
    color: #1496d1;
    font-family: nova-bold;
  }
  
  @media only screen and (max-width: 768px) {
    .tm-list .tm-name {
      font-size: 0.7em;
    }
  }
  