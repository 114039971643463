
.bottom-inner-container{
    text-align: center;
    color: white;
}
.bottom-container{
    background-color: #1496d1;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
}