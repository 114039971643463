.fund-raiser-card {
    border-radius: 10px;
    padding: 20px 0;
    width: 80%;
    margin: auto;
    cursor: pointer;
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    transition: all 250ms ease-in-out;
}
@media (max-width:992px) {
    .fund-raiser-card{
        margin-right: auto!important;
        margin-left: 0!important;
    }
    
}
.fund-raiser-card:hover{
    border: 1px solid #1496d1;
}
.fund-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.fund-icon img {
  height: 140px;
  width: 160px;
}
.fund-title {
    text-align: center;
    font-family: 'nova-bold';
}
