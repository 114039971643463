.admin-container {
  width: 85%;
  margin-left: auto;
  transition: all 500ms ease-in-out;

}
.full-admin-body {
  width: 100%;
  transition: all 500ms ease-in-out;

}
.admin-padding{
    padding: 10px 40px
}

.logo-above-bottom {
  position: relative;
  overflow: hidden;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.logo-above-bottom img {
  position: absolute;
  top: -133px;
  width: 335px;
}
