.service-inner-page-container {
  min-height: 100vh;
}

.service-inner-page-inner-container {
}

.service-inner-page-image-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

@media (max-width: 992px) {
  .service-inner-page-image-container {
    justify-content: center !important;
  }
  .service-inner-page-image-container img {
    width: 100% !important;
  }
  .service-inner-page-container {
    padding: 30px 0 60px;
  }
  .service-inner-left {
    height: auto !important;
    padding: 0 !important;
    margin-bottom: 30px;
  }
  .service-inner-middle {
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 30px;
  }
  .service-inner-right {
    height: auto !important;
    padding: 0 !important;
    margin-bottom: 30px;
  }
}

.service-inner-page-image-container img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 5/3;
}

.service-inner-page-text {
  font-family: nova-light;
  margin-top: 10px;
}

.service-inner-page-heading {
  font-size: 22px;
  text-transform: capitalize;
  font-family: nova-bold;
}
.service-inner-line-heading {
  font-size: 18px;
  text-transform: capitalize;
  font-family: nova-light;
}

.service-price {
  font-size: 18px;
  margin-bottom: 25px;
  font-family: nova-light;
}

.keypoints-container {
  margin-top: 20px;
  margin-bottom: 10px;
}

.key-points-title {
  font-size: 16px;
  font-family: nova-light;
  margin-bottom: 5px;
}

.key-points-list {
}

.service-inner-page-about-heading {
  font-size: 18px;
}

.service-we-accept-container {
  margin-top: 20px;
}

.service-we-accept {
  display: flex;
}

.service-we-accept-heading {
  font-size: 18px;
  font-family: nova-light;
}

.service-we-accept div {
  height: 35px;
  overflow: hidden;
  position: relative;
}

.service-we-accept div:not(:last-child) {
  margin-right: 2px;
}

.service-esewa {
}

.service-esewa img {
  width: 25px;
}

.service-khalti {
}

.service-khalti img {
  width: 35px;
}

.service-ime-pay {
  width: 73px;
}

.service-ime-pay img {
  width: 90px;
  position: absolute;
  top: -18px;
  left: -13px;
}

.service-inner-header {
  font-size: 22px;
  font-family: nova-bold;
  text-transform: capitalize;
}

@media (max-width: 992px) {
}

.service-inner-left {
  padding: 40px 20px 0 0;
  height: calc(100vh - 80px);
  overflow-y: hidden;
}
.service-inner-middle {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  padding: 40px 20px;
}

.service-inner-right {
  padding: 40px 20px;
  margin-bottom: 100px;
  height: calc(100vh - 80px);
  overflow-y: hidden;
  z-index: 3;
}

.service-inner-middle::-webkit-scrollbar {
  width: 3px;
}

.service-inner-middle::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
  width: 5px;
}

.service-inner-middle::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
  width: 5px;
}

.service-inner-middle::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
  width: 5px;
}

.service-inner-middle::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
  width: 5px;
}

.latest-service-title {
  font-size: 22px;
  font-family: "nova-bold";
  margin-bottom: 15px;
}
